import { TabContext, TabList, TabPanel } from '@mui/lab';
import React, { ReactNode, useState } from 'react';
import { Tab } from '@mui/material';
import Box from '@mui/material/Box';

export type TabLayoutProps = {
  tabs: { title: string, id: string; component: ReactNode; disabled?: boolean }[];
  defaultTab: string;
};

const TabLayout = ({ tabs, defaultTab }: TabLayoutProps) => {
  const [tabValue, setTab] = useState<string>(defaultTab);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };
  return (
    <Box>
      <TabContext value={tabValue}>
        <TabList onChange={handleChange}>
          {tabs.map((tab) => (
            <Tab
              key={tab.id}
              label={tab.title}
              value={tab.id}
              disableRipple
              disabled={tab.disabled}
            />
          ))}

        </TabList>
        {tabs.map((tabPanel) => (
          <TabPanel
            key={tabPanel.id}
            value={tabPanel.id}
            sx={{
              p: 0,
              width: '100%',
              '& > div': {
                backgroundColor: 'white', borderRadius: 2, px: 2.5, width: '100%',
              },
            }}
          >
            {tabPanel.component}
          </TabPanel>
        ))}

      </TabContext>
    </Box>

  );
};

export default TabLayout;
