import { IconButton, Stack } from '@mui/material';
import { Close } from '@mui/icons-material';
import EditIcon from 'assets/icons/EditIcon';
import OpenEyeIcon from 'assets/icons/OpenEyeIcon';
import React, { FC, useContext, useState } from 'react';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import {
  Exact,
  TopicQuery, useConfirmOrRejectQuestionMutation, useDeleteQuestionMutation,
} from '@generated/graphql';
import UpdateQuestionModal from 'pages/Topic/view/components/UpdateQuestionModal';
import { ConfirmModalContext } from 'context/confirmModalContext';
import { QuestionItemType } from 'pages/Topic/view/types/topic';
import { ApolloQueryResult } from '@apollo/client';
import QuestionImgButton from './QuestionImgModal';

interface ActionButtonsProps {
  topic: TopicQuery['topic'];
  question: QuestionItemType;
  refetchTopic: (variables?: (Partial<Exact<{ topicId: string }>> | undefined)) =>
  Promise<ApolloQueryResult<TopicQuery>>
}

const ActionButtons: FC<ActionButtonsProps> = ({
  topic, question, refetchTopic,
}) => {
  const isConfirm = question.confirmation;
  const { handleOpen } = useContext(ConfirmModalContext);
  const { addToast } = useContext(ToastContext);

  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);
  const [openImgModal, setOpenImgModal] = useState<boolean>();

  const [deleteQuestion, { loading: deleteLoading }] = useDeleteQuestionMutation();

  const [confirmOrRejectQuestion,
    { loading: confirmRejectLoading },
  ] = useConfirmOrRejectQuestionMutation();

  const onConfirmOrReject = () => confirmOrRejectQuestion({
    variables: {
      questionId: question.id,
    },
  });

  const onDelete = () => deleteQuestion({
    variables: {
      questionId: question.id,
    },
  });

  const action = isConfirm ? onConfirmOrReject : onDelete;

  const handleConfirmOrDelete = () => {
    handleOpen({
      title: isConfirm ? 'Вы уверены, что хотите отклонить вопрос?' : 'Вы уверены, что хотите удалить вопрос?',
      actionButtonText: isConfirm ? 'Отклонить' : 'Удалить',
      loading: confirmRejectLoading || deleteLoading,
      handleClick: async () => action().then(() => {
        refetchTopic();
        addToast({ type: ToastTypeEnum.SUCCESS });
      }).catch(() => addToast({ type: ToastTypeEnum.ERROR })),
    });
  };
  return (
    <Stack
      spacing={0.5}
      sx={{
        top: 16, right: -8, position: 'absolute',
      }}
    >
      <IconButton onClick={handleConfirmOrDelete}>
        <Close sx={{ color: 'text.disabled', width: 20, height: 20 }} />
      </IconButton>
      <IconButton onClick={() => setOpenUpdateModal(true)}>
        <EditIcon sx={{ color: 'text.disabled', width: 20, height: 20 }} />
      </IconButton>
      <IconButton onClick={() => setOpenImgModal(true)}>
        <OpenEyeIcon sx={{ color: 'text.disabled', width: 24, height: 24 }} />
      </IconButton>
      {
        openUpdateModal && (
          <UpdateQuestionModal
            isOpen={openUpdateModal}
            onClose={() => setOpenUpdateModal(false)}
            topic={topic}
            question={question}
            refetchTopic={refetchTopic}
          />
        )
      }
      {
        openImgModal && (
          <QuestionImgButton
            open={openImgModal}
            onClose={() => setOpenImgModal(false)}
            questionId={question.id}
          />
        )
      }
    </Stack>
  );
};

export default ActionButtons;
