import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { generateFilterQuery } from 'components/generators/filtersGenerator';
import CircularLoading from 'components/CircularLoading';
import useList, { DEFAULT_LIMIT } from 'hooks/useList';
import { useNavigate } from 'react-router-dom';
import SortingButton from 'layout/ListLayout/components/SortingButton';
import List from 'layout/ListLayout/components/List';
import ButtonWithPermissions from 'components/buttons/ButtonWithPermissions';
import {
  Identifiable, IOrderInput, ListLayoutProps, Order,
} from 'layout/ListLayout/types';
import { INPUT_DEFAULT_VALUE, InputType } from 'components/generators/inputsGenerator';
import Filters from './components/Filters';

const ListLayout = <Row extends Identifiable, OrderInput extends IOrderInput | undefined = undefined>({
  title, filters, list,
  CardComponent, loading, error,
  refetch, totalCount, defaultOrder,
  sortingList,
  emptyPlaceholderText, isHideCreateButton, createUnavailableText,
}: ListLayoutProps<Row, OrderInput>) => {
  const navigate = useNavigate();

  const {
    limit, page, params, changeSort, order, orderBy,
  } = useList();
  const form = useForm({
    defaultValues: filters.reduce((acc: { [key: string]: null | string | [] }, current) => {
      const isAutocomplete = current.inputType === InputType.AUTOCOMPLETE;
      if (!isAutocomplete || (isAutocomplete && !current.multiple)) {
        acc[current.name] = INPUT_DEFAULT_VALUE[current.inputType];
      } else acc[current.name] = [];

      return acc;
    }, {}),
  });

  const [sorting, setSorting] = useState<{
    order: Order | undefined;
    orderBy: string | undefined
  }>({
    order: order || defaultOrder?.order,
    orderBy: orderBy || defaultOrder?.field,
  });

  useEffect(() => {
    if (refetch) {
      refetch({
        page: page && Number(page) !== 0 ? Number(page) - 1 : 0,
        limit: Number(limit) || DEFAULT_LIMIT,
        filter: generateFilterQuery(filters, params) || {},
        order: sorting.order && sorting.orderBy
          ? {
            order: sorting.order.toLocaleUpperCase(),
            field: sorting.orderBy,
          } as OrderInput : undefined,
      });
    }
  }, [params]);

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2.5}>
        <Typography variant="h28">
          {title}
        </Typography>
        {!isHideCreateButton && (
          <ButtonWithPermissions
            tooltipText={createUnavailableText}
            onClick={() => navigate('create')}
            buttonText="Создать"
          />
        )}
      </Stack>
      <Filters form={form} filters={filters} />
      {!!sortingList && (
      <Stack direction="row" spacing={2.5} mb={2.5}>
        {sortingList.map((button) => (
          <SortingButton
            key={`sorting-button-${button.fieldName}`}
            text={button.buttonText}
            sorting={sorting}
            fieldName={button.fieldName}
            onSort={() => {
              const newSort = {
                order: (sorting.orderBy === button.fieldName && sorting.order === 'asc' ? 'desc' : 'asc') as Order,
                orderBy: button.fieldName,
              };
              changeSort(newSort.order, newSort.orderBy);
              setSorting(newSort);
            }}
          />
        ))}
      </Stack>
      )}
      {loading ? <CircularLoading /> : (
        <List<Row>
          list={list}
          CardComponent={CardComponent}
          emptyPlaceholderText={emptyPlaceholderText}
          error={error}
          totalCount={totalCount}
        />
      )}
    </Box>
  );
};

export default ListLayout;
