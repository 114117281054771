import React, { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { Collapse, Grid, Stack } from '@mui/material';
import ExpandMoreButton from 'components/buttons/ExpandMoreButton';
import Button from '@mui/material/Button';
import Form from 'components/form/Form';
import GeneratedInput from 'components/generators/inputsGenerator';
import { UseFormReturn } from 'react-hook-form';
import { generateQueryOnSubmit, onClearFilter, onUseEffect } from 'components/generators/filtersGenerator';
import { useSearchParams } from 'react-router-dom';
import qs from 'qs';
import { FilterItem } from 'layout/ListLayout/types';

type FiltersProps = {
  form: UseFormReturn;
  filters: FilterItem[];
};

const Filters = ({ form, filters }: FiltersProps) => {
  const {
    reset, handleSubmit, control, watch,
  } = form;
  const [showFilters, setShowFilters] = useState<boolean>(true);
  const showFiltersText = showFilters ? 'скрыть фильтры' : 'показать фильтры';
  const [searchParams, setSearchParams] = useSearchParams();
  const query = useMemo(() => qs.parse(searchParams.toString()), [searchParams]);

  const onSubmit = (data: Record<string, any>) => {
    generateQueryOnSubmit(data, filters, setSearchParams);
  };

  const onClear = () => onClearFilter(filters, reset, setSearchParams);

  useEffect(() => {
    onUseEffect(filters, reset, query);
  }, [query]);

  return (
    <Stack mb={2.5}>
      <Box>
        <Button
          size="small"
          color="secondary"
          disableRipple
          sx={(theme) => ({
            ...theme.typography.body14rg,
            ml: 'auto',
            display: 'flex',
            mb: 1,
            color: theme.palette.base[200],
          })}
          onClick={() => setShowFilters(!showFilters)}
          endIcon={(
            <ExpandMoreButton
              expand={showFilters}
              onClick={() => setShowFilters(!showFilters)}
            />
          )}
        >
          {showFiltersText}
        </Button>
      </Box>

      <Collapse in={showFilters} timeout="auto" unmountOnExit>
        <Box sx={{
          backgroundColor: '#fff',
          borderRadius: 2,
          padding: 3,
          boxShadow: '0px 0px 7.3px 0px rgba(164, 184, 253, 0.24)',
        }}
        >
          <Form form={form} autoComplete="false">
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <Grid container spacing={3} alignItems="flex-end">
                {filters.map((input) => (
                  <Grid item xs={12} md={3} key={`filter-input-${input.name}`}>
                    <GeneratedInput {...{ input, control, watch }} />
                  </Grid>
                ))}
              </Grid>
              <Box flex={1} />
              <Stack spacing={2} direction="row" alignItems="end">
                <Button
                  onClick={onClear}
                  sx={{
                    marginRight: 2,
                  }}
                  color="secondary"
                  variant="contained"
                  size="medium"
                >
                  Сбросить
                </Button>
                <Button variant="contained" type="submit" onClick={handleSubmit(onSubmit)} size="medium">
                  Применить
                </Button>
              </Stack>
            </Stack>
          </Form>
        </Box>
      </Collapse>
    </Stack>
  );
};

export default Filters;
