import React, { useContext, useState } from 'react';
import {
  Card, Divider, IconButton, Stack, Typography,
} from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import SelectControl from 'components/form/SelectControl';
import TextFieldControl from 'components/form/TextFieldControl';
import CardHeader from 'components/CardHeader';
import { get } from 'lodash';
import { ConfirmModalContext } from 'context/confirmModalContext';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'constants/routes';
import { CompetencyMapInputs } from 'pages/CompetencyMaps/view';
import { CompetenceOption, TopicOption } from 'pages/CompetencyMaps/view/components/types';
import AddCompetenceModal from './AddCompetenceModal';

type KnowledgeBlockProps = {
  onRemoveBlock: () => void;
  name: `knowledgeBlocks.${number}`;
  isEdited: boolean;
  form:UseFormReturn<CompetencyMapInputs>;
};

const KnowledgeBlock = ({
  onRemoveBlock, name, isEdited, form,
}:KnowledgeBlockProps) => {
  const navigate = useNavigate();
  const { handleOpen } = useContext(ConfirmModalContext);

  const [openAddCompetence, setAddCompetence] = useState(false);

  const {
    control, watch, getValues, formState: { errors },
  } = form;

  const competenciesName: `knowledgeBlocks.${number}.knowledgeBlockCompetences` = `${name}.knowledgeBlockCompetences`;
  const fieldArray = useFieldArray({
    control,
    name: competenciesName,
    keyName: 'formId',
  });
  const { fields, append, remove } = fieldArray;

  const addCompetency = (competence: CompetenceOption | undefined | null) => {
    if (competence) {
      append(competence);
      setAddCompetence(false);
    }
  };
  const isError = !!get(errors, competenciesName)?.root?.message;

  const title = watch(`${name}.title`);

  const onRemoveCompetence = (index: number) => {
    handleOpen({
      title: 'Вы уверены, что хотите удалить компетенцию?',
      handleClick: () => remove(index),
    });
  };

  return (
    <Card
      sx={{
        height: '100%',
        maxHeight: '100vh',
        minWidth: 295,
        maxWidth: 295,
        border: isError ? '1px solid' : undefined,
        borderColor: 'error.main',
      }}
      component={Stack}
    >
      <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }} alignItems="center">
        {
          isEdited
            ? (
              <TextFieldControl
                placeholder="Название"
                name={`${name}.title`}
                size="small"
                fullWidth
                rules={{ required: true }}
              />
            )
            : (<CardHeader title={title} />)
        }
        {isEdited && (
        <IconButton
          onClick={onRemoveBlock}
          component={Close}
          sx={{
            fontSize: 34, color: 'base.200', right: 0, top: 'center',
          }}
        />
        )}
      </Stack>
      <Divider sx={{ mt: 1.5, mb: 2 }} />
      <Stack spacing={2} sx={{ overflow: 'auto' }}>
        {fields?.length
          ? fields.map((field, index) => {
            const topics = getValues(`${competenciesName}.${index}.topics`);
            return (
              <Stack direction="row" spacing={1.5} justifyContent="space-between" alignItems="center">
                <SelectControl<TopicOption>
                  items={topics || []}
                  labelName="title"
                  valueName="id"
                  readOnly
                  name={`knowledgeBlockCompetences.${index}`}
                  key={field.formId}
                  sx={{ backgroundColor: 'bgSwitch' }}
                  displayEmpty
                  textColor="primary"
                  renderValue={(selected) => {
                    if (!selected) {
                      return getValues(`${competenciesName}.${index}.title`);
                    }
                    return '';
                  }}
                  fullWidth
                  onItemClick={(item) => navigate(`/${ROUTES.TOPIC}/view/${item.id}`)}
                />
                {isEdited && (
                <IconButton
                  onClick={() => onRemoveCompetence(index)}
                  component={Close}
                  sx={{
                    fontSize: 34, color: 'base.200',
                  }}
                />
                )}
              </Stack>
            );
          })
          : <Typography variant="body14rg" sx={{ color: 'text.disabled' }}>Добавьте компетенции</Typography>}
      </Stack>
      <Box flex={1} />
      {isEdited && (
      <Button
        startIcon={<Add />}
        variant="contained"
        color="secondary"
        onClick={() => setAddCompetence(true)}
        sx={{
          mt: 2,
          width: 'max-content',
          backgroundColor: 'bgSwitch',
          '&:hover': { backgroundColor: 'bgSwitch' },
          '&:active': { backgroundColor: 'bgSwitch' },
          '&.Mui-disabled': { backgroundColor: 'bgSwitch' },
        }}
      >
        Компетенция
      </Button>
      )}
      {
        openAddCompetence && (
        <AddCompetenceModal
          open={openAddCompetence}
          onClose={() => setAddCompetence(false)}
          onAdd={addCompetency}
        />
        )
      }
    </Card>
  );
};

export default KnowledgeBlock;
