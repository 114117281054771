import { Stack, Typography } from '@mui/material';
import { formatISODate } from 'helpers/date';
import TestingStatus from 'components/TestingStatus';
import React from 'react';
import { TrialInfoQuery } from '@generated/graphql';

interface TrialHistoryProps {
  trial: TrialInfoQuery['trial']
}

const TrialHistory = ({ trial }: TrialHistoryProps) => (
  <Stack spacing={2}>
    {
        trial?.trialStatusHistory?.map((status) => (
          <Stack
            key={`trial-history-item-${status.id}`}
            direction="row"
            alignItems="center"
            justifyContent="end"
            spacing={2}
            sx={{
              width: '100%', backgroundColor: 'bgSwitch', px: 2, py: 1.5,
            }}
          >
            <Typography variant="body16rg">
              {formatISODate(status.createdAt, 'dd.MM.yyyy HH:mm')}
            </Typography>
            <TestingStatus status={status.newStatus} />
          </Stack>
        ))
      }
  </Stack>
);

export default TrialHistory;
