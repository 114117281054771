import TextFieldControl from 'components/form/TextFieldControl';
import { Link, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CopyButton from 'components/buttons/CopyButton';
import React from 'react';
import useIsCreate from 'hooks/useIsCreate';
import { UseFormReturn } from 'react-hook-form';
import { InfoInputs } from '../types';

interface ITrialInfoProps {
  form: UseFormReturn<InfoInputs>
}

const TrialInfo = ({ form }: ITrialInfoProps) => {
  const isCreate = useIsCreate();
  const link = form.watch('link');

  return (
    <>
      <TextFieldControl name="createdAt" label="Дата создания тестирования" disabled />
      {!isCreate && (
        <Stack spacing={0.5}>
          <Typography variant="body14rg" sx={{ color: 'base.500' }}>
            Ссылка на тестирование
          </Typography>
          <Box sx={{
            backgroundColor: 'bgSwitch', px: 1.5, py: 1.3, borderRadius: 2,
          }}
          >
            <CopyButton copyText={link!}>
              <Link variant="body16rg" href={link} sx={{ textDecoration: 'none' }} target="_blank">
                {link}
              </Link>
            </CopyButton>

          </Box>
        </Stack>
      )}
      <TextFieldControl name="startedAt" label="Начало тестирования" disabled />
      <TextFieldControl name="completedAt" label="Завершение тестирования" disabled />
    </>
  );
};

export default TrialInfo;
