import React from 'react';
import { RegisterOptions, useController } from 'react-hook-form';
import Select, { ISelectItem, SelectCustomProps } from 'components/inputs/Select';

interface SelectControlProps<TSelectItem extends Record<string, any> = ISelectItem>
  extends Omit<SelectCustomProps<TSelectItem>, 'value' | 'onChange'> {
  name: string;
  rules?: RegisterOptions;
}

const SelectControl = <TSelectItem extends Record<string, any> = ISelectItem>({
  items, name, rules, sx, ...props
}: SelectControlProps<TSelectItem>) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, rules });
  return (
    <Select
      items={items}
      value={field.value}
      onChange={field.onChange}
      error={error}
      sx={sx}
      {...props}
    />
  );
};

export default SelectControl;
