import React, { useState } from 'react';
import Autocomplete from 'components/inputs/Autocomplete';
import CustomModal from 'components/modals/CustomModal';
import { useCompetenceOptionsWithTopicsQuery } from '@generated/graphql';
import { Stack, Typography } from '@mui/material';
import { CompetenceOption } from './types';

type AddCompetenceModalProps = {
  open: boolean;
  onClose: () => void;
  onAdd: (competence: CompetenceOption | undefined | null) => void
};

const AddCompetenceModal = ({ open, onClose, onAdd }: AddCompetenceModalProps) => {
  const { data, loading } = useCompetenceOptionsWithTopicsQuery();
  const [value, setValue] = useState<CompetenceOption | null>();
  const onChange = (inputData: CompetenceOption | null) => {
    setValue(inputData);
  };

  const onSubmit = () => onAdd(value);
  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title="Добавить компетенцию"
      maxWidth="sm"
      content={(
        <Autocomplete<CompetenceOption>
          onChange={onChange}
          value={value}
          options={data?.competences.data || []}
          loading={loading}
          optionName="title"
          renderOption={(props, option) => (
            <li {...props} key={`competence-${option.id}`}>
              <Stack spacing={0.5}>
                <Typography variant="body14rg" sx={{ color: 'primary.main' }}>
                  {option.title}
                </Typography>
                <Stack direction="row" spacing={1.5}>
                  {!!option.grades?.length && (
                  <Typography variant="body14rg" sx={{ '& > span': { color: 'base.500' } }}>
                    <span>грейд: </span>
                    {option.grades?.join(', ')}
                  </Typography>
                  )}
                  {option.programingLanguage && (
                  <Typography variant="body14rg" sx={{ '& > span': { color: 'base.500' } }}>
                    <span>навык: </span>
                    {option.programingLanguage}
                  </Typography>
                  )}
                </Stack>
              </Stack>
            </li>
          )}
        />
      )}
      handleClick={onSubmit}
      buttonText="Добавить"
    />

  );
};

export default AddCompetenceModal;
